import { useCallback, useState } from 'react'

export default function useToggle(initialValue: boolean | (() => boolean) = false) {
  const [value, setValue] = useState<boolean>(initialValue)

  const on = useCallback(() => setValue(true), [])
  const off = useCallback(() => setValue(false), [])
  const toggle = useCallback(() => setValue(v => !v), [])

  return { value, on, off, toggle }
}
